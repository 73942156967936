const getters = {
  calculator: state => state.user.calculatorInfo,
  profile: state => state.user.profileInfo,
  income: state => state.user.incomeInfo,
  attachment: state => state.user.attachmentInfo,

  calculatorFormIsValid: state => state.user.calculatorFormIsValid,
  profileFormIsValid: state => state.user.profileFormIsValid,
  incomeFormIsValid: state => state.user.incomeFormIsValid,
  attachmentFormIsValid: state => state.user.attachmentFormIsValid,

  overlay: state => state.user.overlay,
  overlayText: state => state.user.overlayText,

  resubmit: state => state.user.resubmit,
  submitted: state => state.user.submitted,
  
  fb_fname: state => state.user.fb_fname,
  fb_lname: state => state.user.fb_lname,
  fb_messengerid: state => state.user.fb_messengerid,
  fb_gender: state => state.user.fb_gender,
  fb_profilepic: state => state.user.fb_profilepic,

  base_url: state => state.user.base_url,
}
export default getters
