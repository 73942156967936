import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
import Layout from '@/layout'

export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: '/calculator',
    children: [
        {
            path: 'calculator',
            component: () => import('@/views/Calculator'),
            name: 'Calculator',
            meta: { title: 'Calculator'}
        }
      ],
  },

  {
    path: '/profile',
    component: Layout,
    redirect: '/profile/index',
    children: [
        {
            path: 'index',
            component: () => import('@/views/Profile'),
            name: 'Profile',
            meta: { title: 'Profile'}
        }
    ],
    beforeEnter: (to, from, next) => {
      if(from.name == null)
          next({name: 'Calculator'})
      else
          next()
    }
  },

  {
    path: '/income',
    component: Layout,
    redirect: '/income/index',
    children: [
        {
            path: 'index',
            component: () => import('@/views/Income'),
            name: 'Income',
            meta: { title: 'Income'}
        }
    ],
    beforeEnter: (to, from, next) => {
      if(from.name == null)
          next({name: 'Calculator'})
      else
          next()
    }
  },


  {
    path: '/attachment',
    component: Layout,
    redirect: '/attachment/index',
    children: [
        {
            path: 'index',
            component: () => import('@/views/Attachment'),
            name: 'Attachment',
            meta: { title: 'Attachment'}
        }
    ],
    beforeEnter: (to, from, next) => {
      if(from.name == null)
          next({name: 'Calculator'})
      else
          next()
    }
  },

  {
    path: '/last-page',
    component: () => import('@/views/LastPage'),
  },

  {
    path: '/terms-and-conditions',
    component: () => import('@/views/TermsAndConditions'),
    name: 'TermsAndConditions',
    beforeEnter: (to, from, next) => {
      if(from.name == null)
          next({name: 'Calculator'})
      else
          next()
    }
  },


  


  // 404 page must be placed at the end !!!
  {
    path: '*',
    component: Layout,
    redirect: '/calculator',
  },
]

const createRouter = () => new Router({
  mode: 'history', // require service support
  base: '/',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router

